import React, { createContext, PropsWithChildren, useEffect, useState } from "react";

interface User {
  email: string;
  name?: string;
  company?: string;
  admin?: boolean;
}

interface AuthContextInterface {
  isAuthenticated: boolean;
  user: User | null;
  logIn(u: User): void;
  logOut(): void;
}

export const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  logIn: (u: User) => {},
  logOut: () => {},
} as AuthContextInterface);

const USER_KEY = 'user';

const AuthProvider: React.FC<PropsWithChildren> = ({children}) => {
  const getUser = () => {
    const x = localStorage.getItem(USER_KEY)
    try {
      return x ? JSON.parse(x) : null;
    } catch (e) {
      localStorage.removeItem(USER_KEY);
    }
  };

  const [user, setUser] = useState<User | null>(getUser());
  const [isAuthenticated, setIsAuthenticated] = useState(!!user);

  const logIn = (u: User) => {
    localStorage.setItem(USER_KEY, JSON.stringify(u));
    setUser(u);
  }

  const logOut = () => {
    localStorage.removeItem(USER_KEY);
    setUser(null);
  }

  useEffect(() => {
    setIsAuthenticated(!!user);
  }, [user]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      user,
      logIn,
      logOut,
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;