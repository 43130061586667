import { useEffect, useRef } from "react";

const useTitle = (title: string) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (document.title !== title) {
      document.title = `${title} - CLA ShareRight`
    }

    return () => {
      document.title = defaultTitle.current;
    }
  }, []);
}

export default useTitle;