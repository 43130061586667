import React from "react";

import "./Home.scss";

import logoCloser from "../../assets/brands/Closer.png";
import logoDrapers from "../../assets/brands/logo-drapers2.svg";
import logoPersonnelToday from "../../assets/brands/Personnel_Today.png";
import logoPRWeek from "../../assets/brands/PR_Week.png";
import logoTR from "../../assets/brands/TR-logo.svg";
import logoTTG from "../../assets/brands/TTG_Media.png";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import useTitle from "../../hooks/useTitle";


export function Home() {
  useTitle('Home');

  return (
    <div className="homeContainer container">
      <div className="homeBody">
        <div className="descriptionText">
          <header>Get more value from your CLA Licence</header>

          <p>Share copies of the latest web articles with your colleagues, hassle free.</p>

          <p style={{ marginBottom: 0 }}>Make your subscriptions go further and join our exclusive Beta community to
            trial ShareRight in your organisation.</p>

          <p style={{ marginBottom: 0, marginTop: "0.25rem", fontWeight: "bold" }}>Copy, Right … with CLA ShareRight</p>

          <Link to="/register" className="button home-button">Get ShareRight, free with your CLA Licence</Link>
        </div>
        <div className="descriptionImage">
          <ReactPlayer className="descriptionImg" url="https://www.youtube.com/watch?v=-KC8nu97lQY"/>
        </div>
      </div>
      <hr/>
      <h5 style={{ marginBottom: "1rem" }}>Repertoire sites include...</h5>
      <div className="homeBrands">
        <a href="https://www.drapersonline.com/"
           target="_blank"
           title="Go to 'Drapers'"><img src={logoDrapers} alt="drapersonline.com logo"/></a>
        <a href="https://www.personneltoday.com/"
           target="_blank"
           title="Go to 'Personnel Today'"><img src={logoPersonnelToday} alt="personneltoday.com logo"/></a>
        <a href="https://closeronline.co.uk/"
           target="_blank"
           title="Go to 'Closer'"><img src={logoCloser} alt="closeronline.co.uk logo"/></a>
        <a href="https://www.prweek.com/uk"
           target="_blank"
           title="Go to 'PR Week'"><img src={logoPRWeek} alt="prweek.com logo"/></a>
        <a href="https://www.talkingretail.com/"
           target="_blank"
           title="Go to 'Talking Retail'"><img src={logoTR} alt="talkingretail.com logo"/></a>
        <a href="https://www.ttgmedia.com/"
           target="_blank"
           title="Go to 'ttg'"><img src={logoTTG} alt="ttgmedia.com logo"/></a>
      </div>
      <hr/>
      <div className="description2">
        <div className="descriptionText2">
          <h5>How does ShareRight help?</h5>
          <ul>
            <li>
              Share your premium web articles with colleagues without expensive additional subscription costs
            </li>
            <li>
              Maximise the impact of the content you read, in a simple and professional way
            </li>
            <li>Save time by sharing seamlessly as you browse</li>
            <li>
              Be assured that you are sharing content in a responsible and legal way
            </li>
            <li>
              Keep track of your saved articles; your article is preserved for you under your CLA Licence
            </li>
          </ul>
        </div>
        <div className="descriptionImage2">

        </div>
      </div>
      <div className="homeCTA">
        <div className="imageContainer">

        </div>
        <div className="CTA">
          <h5>Getting started with ShareRight</h5>
          <p>ShareRight is a browser extension that takes an accurate copy of the page you are viewing and saves it to
            your ShareRight account, ready for you to share with your colleagues - now or later. All in just a few
            clicks.</p>
          <p>Getting started is easy:</p>
          <ul>
            <li><strong>Create</strong> your ShareRight account, which is your unique place to save and share content
              under your CLA Licence
            </li>
            <li>Head to your browser app store (Chrome, or Edge) and <strong>download</strong> the ShareRight extension
            </li>
            <li><strong>Discover</strong> some of the premium websites you can use with ShareRight</li>
            <li><strong>Capture</strong> your favourite articles by pressing the ShareRight button on your toolbar</li>
            <li>Follow out guided workflows to <strong>share</strong> with your colleagues, either as a unique
              ShareRight link or via your email
            </li>
            <li>Relax in the knowledge that, by sharing the page via ShareRight, you are
              helping <strong>sustain</strong> the livelihood of the content creators you enjoy
            </li>
          </ul>
          <p style={{ marginBottom: 0, marginTop: "0.25rem", fontWeight: "bold" }}>Copy, Right … with CLA ShareRight</p>
          <Link to="/register" className="button home-button">Get ShareRight, free with your CLA Licence</Link>
        </div>
      </div>
    </div>
  );
};
