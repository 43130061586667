import React, { useEffect, useState } from "react";
import Contact from "../../types/contact.type";
import useAPI from "../../api";
import styles from "./EmailAutocompleteInput.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface Props {
  onAdd: (val: string) => void;
  setEmailInput: (value: string) => void;
  hide?: string[];
}

export function EmailAutocompleteInput({ onAdd, setEmailInput, hide }: Props) {
  const [value, setValue] = useState('');
  const [contacts, setContacts] = useState<Contact[]>([]);

  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([])
  const [selectedIndex, setSelectedIndex] = useState(0);

  const api = useAPI();

  const loadContacts = () => {
    api.contacts.get()
      .then((body) => {
        if (body.contacts) {
          setContacts(body.contacts)
        }
      })
  }

  const selectSuggestion = (i: number) => {
    if (filteredContacts.length === 0) {
      return;
    }
    setValue('');
    setEmailInput('');
    onAdd(filteredContacts[i].email);
    setFilteredContacts([]);
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case " ":
        if (e.ctrlKey) {
          // Ctrl+Space to show initial suggestions without having to type a letter
          e.preventDefault();

          if (value === "") {
            const filtered = contacts
              .filter((c) => !hide || !hide.includes(c.email))
              .sort((a, b) => a.priority - b.priority);
            setFilteredContacts(filtered);
          }
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((filteredContacts.length + selectedIndex - 1) % filteredContacts.length);
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((selectedIndex + 1) % filteredContacts.length)
        break;
      case "Enter":
        e.preventDefault();
        selectSuggestion(selectedIndex);
        break;
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setEmailInput(e.target.value);
  }

  const onBlur = () => {
    setValue('');
    setEmailInput('');
    onAdd(value);
  }

  useEffect(() => {
    setSelectedIndex(0);

    if (value.length === 0) {
      setFilteredContacts([]);
      return;
    }

    const filtered = contacts
      .filter((c) => {
        return (c.email.startsWith(value) || c.name?.startsWith(value))
          && (!hide || !hide.includes(c.email))
      })
      .sort((a, b) => a.priority - b.priority)

    setFilteredContacts(filtered);
  }, [value])

  useEffect(() => {
    loadContacts();
  }, [])

  return (
    <div className={styles.container}>
      <div>
        <input type="text"
               placeholder="Enter an email address"
               onChange={onChange}
               onKeyDown={onKeyDown}
               value={value}
               onBlur={onBlur}
               className={styles.input}
        />

        <FontAwesomeIcon icon={faPlus} className="emails-add-btn" onClick={() => onAdd(value)}
                         title="Add email(s) to Share list"/>
      </div>

      {filteredContacts.length > 0 ?
        <div className={styles.suggestions_container}>
          <ul className={styles.suggestions}>
            {filteredContacts.map((c, i) =>
              <li key={c.email}>
                <button className={`${styles.suggestion} ${selectedIndex === i ? styles.selected : ''}`}
                        onClick={() => selectSuggestion(i)}>
                  {c.email}
                  {c.name && ` - ${c.name}`}
                  {c.organisation && ` (${c.organisation})`}
                </button>
              </li>
            )}
          </ul>
        </div>
        : <></>}
    </div>
  )
}