import { Link, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "../../providers/AuthContext";
import useAPI from "../../api";
import "./Navbar.scss";
import { ADMIN_URL, PRODUCT_NAME } from "../../config";

export  function Navbar() {
  const { user, logOut } = useContext(AuthContext);
  const api = useAPI();
  const navigate = useNavigate();

  const logOutHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    api.auth.logOut()
      .then((body) => {
        console.log("Log out:");
        console.log(body);
      })
      .finally(() => {
        logOut();
        navigate("/login");
      });
  };

  return (
    <nav className="navbar navbar-expand">
      <div className="navbar-inner container justify-content-between">
        <div className="navbar-nav flex-row">
          <Link to="/home" className="navbar-brand">
            <img className="logo" src="/logo/logo.png" alt={`${PRODUCT_NAME} logo`}/>
          </Link>
          <li className="nav-item">
            <Link to="/home" className="nav-link">
              Home
            </Link>
          </li>
          {user &&
            <li className="nav-item">
              <Link to="/about" className="nav-link">
                About
              </Link>
            </li>
          }
          {user?.admin && <li className="nav-item">
            <a href={ADMIN_URL} className="nav-link" target="_blank">
              Admin
            </a>
          </li>}
        </div>

        {user ? (
          <div className="navbar-nav">
            <li className="nav-item">
              <Link to="/account" className="nav-link">
                {user.email}
              </Link>
            </li>
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOutHandler}>
                Log out
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/login" className="nav-link">
                Log in
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/register" className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </div>
    </nav>
  );
}