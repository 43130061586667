import styles from "./Checkbox.module.scss";

export function Checkbox({ label, checked, onChange, disabled }: any) {
  return (
    <div className={styles.checkbox_wrapper}>
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled}/>
        <span>{label}</span>
      </label>
    </div>
  );
};
