import "./Footer.scss";
import { Link } from "react-router-dom";

import logo from "../../assets/logo/logo.png";
import {
  CLA_ACCESSIBILITY_URL,
  CLA_ANTI_SLAVERY_URL,
  CLA_COPYRIGHT_NOTICE_URL,
  CLA_KNOWLEDGE_BASE_URL,
  CLA_PRIVACY_POLICY_URL,
  CONTACT_CLA_EMAIL, PRODUCT_NAME,
} from "../../config";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footerInner container">
        <div className="footerLogo">
          <a href="https://www.cla.co.uk/" target="_blank" className="navbar-brand CLALogo">
            <img src={logo} alt={`${PRODUCT_NAME} logo`}/>
          </a>
        </div>
        <div className="footerAddress">
          <ul>
            <li>The Copyright Licensing Agency Ltd / CLA</li>
            <li>1 St. Katharine’s Way</li>
            <li>London E1W 1UN</li>
          </ul>
        </div>
        <div className="footerLinks">
          <ul>
            <li><a href={CLA_COPYRIGHT_NOTICE_URL} target="_blank">Copyright Notice</a></li>
            <li><a href={CLA_PRIVACY_POLICY_URL} target="_blank">Privacy Policy</a></li>
            <li><Link to="/terms-of-use" target="_blank">Terms of Use</Link></li>
            <li><a href={CLA_ACCESSIBILITY_URL} target="_blank">Accessibility Policy</a></li>
            <li><a href={CLA_ANTI_SLAVERY_URL} target="_blank">Anti-Slavery Policy</a></li>
          </ul>
        </div>
        <div className="footerContact">
          <ul>
            <li><p>Registered in England No. 1690026</p></li>
            <li><a href={`mailto:${CONTACT_CLA_EMAIL}`}>Contact us</a></li>
            <li><a href={CLA_KNOWLEDGE_BASE_URL} target="_blank">Knowledge Base</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}