import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthProvider from "./providers/AuthContext";
import { ProtectedRoute } from "./components";
import {
  Account,
  About,
  Error,
  ForgotPassword,
  Home,
  Login,
  MyCopies,
  Register,
  ResetPassword,
  Share,
  TermsOfUse,
  ViewCopy,
} from "./views";

const router = createBrowserRouter([
  { path: "/s/:id", element: <ViewCopy/> },
  {
    element: <App/>,
    errorElement: <Error/>,
    children: [
      { path: "/terms-of-use", element: <TermsOfUse/> },
      {
        element: <ProtectedRoute authState={true} redirPath="/login"/>,
        children: [
          { path: "/", element: <MyCopies/> },
          { path: "/account", element: <Account/> },
          { path: "/about", element: <About/> },
          { path: "/share/:id", element: <Share/> },
        ],
      },
      {
        element: <ProtectedRoute authState={false} redirPath="/"/>,
        children: [
          { path: "/home", element: <Home/> },
          { path: "/login", element: <Login/> },
          { path: "/register", element: <Register/> },
          { path: "/reset-password", element: <ResetPassword/> },
          { path: "/forgot-password", element: <ForgotPassword/> },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router}/>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
