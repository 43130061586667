import styles from "./EmailCheckModal.module.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";


interface EmailCheckModalProps {
  emails: string[]
  onCancel: () => void,
  onConfirm: (confirmedEmails: string[], linkOnlyEmails: string[]) => void,
}

export function EmailCheckModal({ emails, onCancel, onConfirm }: EmailCheckModalProps) {
  const [confirmedEmails, setConfirmedEmails] = useState<string[]>([]);
  const [linkOnlyEmails, setLinkOnlyEmails] = useState<string[]>(emails);
  const [allSelected, setAllSelected] = useState(false);

  const toggleSelectAll = () => {
    if (allSelected) {
      setConfirmedEmails([]);
      setAllSelected(false);
      return;
    }
    setConfirmedEmails(emails);
    setAllSelected(true);
  }

  useEffect(() => {
    if (emails.length === confirmedEmails.length) {
      setAllSelected(true);
    } else if (allSelected) {
      setAllSelected(false);
    }
  }, [confirmedEmails]);

  const cancel = () => {
    setConfirmedEmails([]);
    setLinkOnlyEmails([]);
    onCancel();
  };

  const submit = () => {
    onConfirm(confirmedEmails, linkOnlyEmails);
  };

  const isConfirmed = (email: string) => {
    return confirmedEmails.indexOf(email) !== -1;
  };

  const toggleConfirmed = (email: string) => {
    const i = confirmedEmails.indexOf(email);
    if (i !== -1) {
      setConfirmedEmails(confirmedEmails.filter((e) => e !== email));
      setLinkOnlyEmails([...linkOnlyEmails, email]);
    } else {
      setConfirmedEmails([...confirmedEmails, email]);
      setLinkOnlyEmails(linkOnlyEmails.filter((e) => e !== email));
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <h3>Let's check these emails!</h3>
        <p>
          The email accounts you'd like to share with are in a different domain from yours.
        </p>
        <p>
          Please tick all addresses that you are permitted to share with under the terms of your CLA Licence:
        </p>

        <FontAwesomeIcon icon={faX}
                         className={styles.cancel_icon}
                         onClick={cancel}/>

        <ul className={styles.emails}>
          {emails.length > 1 &&
            <li>
              <label className={styles.email_label}>
                <input type="checkbox"
                       checked={allSelected}
                       onChange={() => toggleSelectAll()}/>
                Select all
              </label>
            </li>
          }
          {emails.map((email) => (
            <li key={email} className={styles.email}>
              <label className={styles.email_label}>
                <input type="checkbox"
                       checked={isConfirmed(email)}
                       onChange={() => toggleConfirmed(email)}/>
                {email}
              </label>

              {isConfirmed(email) ?
                <i>Permitted under my organisation's CLA Licence</i> :
                <i>Outside the terms of the CLA Licence; a link will be sent instead</i>}
            </li>
          ))}
        </ul>

        <div className={styles.action_buttons}>
          <button type="button" onClick={cancel} className="link-btn">Cancel</button>
          <button type="button" onClick={submit} className="btn-primary">Submit</button>
        </div>
      </div>
    </div>
  );
}