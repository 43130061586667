import styles from "./Account.module.scss";
import { useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { useNavigate } from "react-router-dom";
import useAPI from "../../api";
import useTitle from "../../hooks/useTitle";

export function Account() {
  useTitle('Account');

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [name, setName] = useState<string>(user?.name ?? '');

  const [submitting, setSubmitting] = useState(false);

  const api = useAPI();

  const submit = () => {
    setSubmitting(true);
    setMessage("");

    api.user.saveDetails(name)
    .then((body) => {
      if (body.msg) {
        setMessage(body.msg);
      }
    })
    .finally(() => setSubmitting(false))
  };

  return (
    <div>
      <h3>My Details</h3>
      {message}
      <table className={styles.details_table}>
        <tbody>
        <tr>
          <td>Email:</td>
          <td>
            {user?.email}
          </td>
        </tr>
        <tr>
          <td>Name:</td>
          <td>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
          </td>
        </tr>
        <tr>
          <td>Organisation:</td>
          <td>{user?.company}</td>
        </tr>
        </tbody>
      </table>

      <div className={styles.action_buttons}>
        <button type="button" onClick={() => navigate("/")} disabled={submitting} className="link-btn">Cancel</button>
        <button type="button" onClick={submit} disabled={submitting} className="btn-primary">Save</button>
      </div>

    </div>
  );
}