import { useContext } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface ProtectedRouteProps {
  authState: boolean;  // require user to be either logged in (true) or logged out (false)
  redirPath: string;   // path to redirect to if auth state is not valid
}

export function ProtectedRoute({ authState, redirPath }: ProtectedRouteProps) {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  if ((authState && isAuthenticated) || (!authState && !isAuthenticated)) {
    return <Outlet/>;
  }

  return <Navigate to={redirPath} replace state={{ path: location.pathname }}/>;
}