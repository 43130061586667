import useAPI from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import React, { useState } from "react";
import "./RecordFavoriteButton.scss";

interface RecordFavoriteButtonProps {
  id2: string;
  favorited: boolean;
  style?: any;
}

export const RecordFavoriteButton: React.FC<RecordFavoriteButtonProps> = (props) => {
  const [favorited, setFavorited] = useState(props.favorited);
  const api = useAPI();

  const toggleFavorited = () => {
    if (favorited) {
      api.record.removeFavorite(props.id2)
        .then(() => setFavorited(false));
    } else {
      api.record.addFavorite(props.id2)
        .then(() => setFavorited(true));
    }
  };

  return (
    <button className="icon-btn record-favorite-button" onClick={toggleFavorited} style={{...props.style}}>
      {favorited ?
        <FontAwesomeIcon icon={faStarSolid} title="Remove from favorites"/> :
        <FontAwesomeIcon icon={faStarRegular} title="Add to favorites"/>
      }
    </button>
  );
};
