import useAPI from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "./RecordDeleteButton.scss";

interface RecordDeleteButtonProps {
  id2: string;
  style?: any;
  afterDelete?: React.MouseEventHandler;
}

export const RecordDeleteButton: React.FC<RecordDeleteButtonProps> = (props) => {
  const api = useAPI();

  const handleDelete = (e: React.MouseEvent) => {
    api.record.delete(props.id2)
      .then(() => {
        if (props.afterDelete) {
          props.afterDelete(e);
        }
      });
  };

  return (
    <button className="icon-btn delete-favorite-button" onClick={(e) => handleDelete(e)} style={{ ...props.style }}>
      <FontAwesomeIcon icon={faTrash} title="Delete copy"/>
    </button>
  );
};
