import IRecord from "../../types/record.type";
import "./RecordThumbnail.scss";
import logo from "../../assets/logo/logo.png";

interface RecordThumbnailProps {
  record: IRecord;
  wide?: boolean;
}

export function RecordThumbnail(props: RecordThumbnailProps) {
  // TODO: Better fallbacks when record doesn't have thumbnail

  return (
    <div className={`record-thumbnail-container ${props.wide ? 'wide' : ''}`}>
      {props.record.thumbnail ? (
        <img src={props.record.thumbnail} alt="Copy thumbnail"/>
      ) : <div className="empty-thumbnail"><img src={logo} className="faded-logo" alt="Empty copy thumbnail"/></div>}
    </div>
  )
}