import "./EmailCard.scss";
import { useContext } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { getDomain } from "../../common/email";

interface EmailCardProps {
  email: string;
  name?: string | null;
  canDelete?: boolean;

  onRemove(email: string): void;
}

export function EmailCard(props: EmailCardProps) {
  const { user } = useContext(AuthContext);

  const getInitials = () => {
    let s = props.name ?? props.email;

    if (s.indexOf("@") !== -1) {
      s = s.split('@')[0].replaceAll('.', ' ');
    }
    return s.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  const isOutsideOrganization = () => {
    if (!user) {
      return false;
    }
    return getDomain(user.email) !== getDomain(props.email);
  };

  const initials = getInitials(); // TODO: implement

  return (
    <div className="email-card">
      <div className="card-inner">
        <div className="picture">{initials}</div>
        <div className="email">
          {props.name && <div>{props.name}</div>}
          <div>{props.email}</div>
          {isOutsideOrganization() && <div className="card-warning"
                                           title={`This person's email domain (${getDomain(props.email)}) does not match yours`}
          >Outside organisation</div>}
        </div>
        {props.canDelete && <button className="remove" onClick={() => props.onRemove(props.email)}>&times;</button>}
      </div>
    </div>
  );
}