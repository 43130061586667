import React, { useContext, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import useAPI from "../../api";
import { AuthContext } from "../../providers/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./Register.scss";
import { CLA_PRIVACY_POLICY_URL, PRODUCT_NAME } from "../../config";
import { PasswordField } from "../../components";
import useTitle from "../../hooks/useTitle";

export const Register: React.FC = () => {
  useTitle('Sign up');

  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const api = useAPI();
  const { logIn } = useContext(AuthContext);

  const initialValues: {
    email: string,
    password: string,
    repeatPassword: string,
    company: string,
    agree: boolean,
  } = {
    email: "",
    password: "",
    repeatPassword: "",
    company: "",
    agree: false,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("not a valid email")
      .required("Field is required"),
    password: Yup.string()
      .test(
        "len",
        "Must be at least 8 characters",
        (val: any) =>
          val &&
          val.toString().length >= 8,
      )
      .required("Field is required")
      .matches(
        /^(?=.*[0-9])/,
        "Must contain at least one number",
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must contain at least one special character (e.g. '@')",
      ),
    repeatPassword: Yup.string()
      .required("Field is required")
      .oneOf(
        [Yup.ref('password'), null],
        'Passwords must match',
      ),
    company: Yup.string(),
    agree: Yup.boolean().oneOf([true], 'You must agree to proceed'),
  });

  const handleRegister = (formValue: { email: string; password: string; repeatPassword: string; company: string }) => {
    setSubmitting(true);
    const { email, password, company } = formValue;

    api.auth.register(email, password, company).then((body) => {
      if (body && body.type === "success") {
        logIn({ email, company });
        console.log("Successfully registered");
        navigate("/about");
        window.location.reload();
        setSuccessful(true);
      } else if (body.type === "error" && body.msg) {
        setMessage(body.msg);
      }
    }).catch((error) => {
        setMessage("An error occurred, please try again");
        setSuccessful(false);
      },
    ).finally(() => setSubmitting(false));
  };

  return (
    <div className="col-md-12">
      <div className="card card-container cla-card">
        <div>
          <h1>Sign up for {PRODUCT_NAME}</h1>
          <div className="card-inner">
            <a>The save-and-share service from the Copyright Licensing Agency</a>
          </div>
        </div>
        <div className="card-inner">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
            <Form>
              {!successful && (
                <div>
                  <div className="form-group">
                    <label htmlFor="email"> Email </label>
                    <Field id="email" name="email" type="email" className="form-control"/>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password"> Password </label>
                    <PasswordField id="password" name="password"/>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="repeatPassword"> Confirm Password </label>
                    <PasswordField id="repeatPassword" name="repeatPassword"/>
                    <ErrorMessage
                      name="repeatPassword"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="company"> Company/organisation (optional) </label>
                    <Field id="company" name="company" className="form-control"/>
                    <ErrorMessage
                      name="company"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label>
                      <Field id="agree" name="agree" className="agree" type="checkbox"/>
                      <span>I agree to {PRODUCT_NAME}'s <Link to="/terms-of-use"
                                                              target="_blank">Terms of Use</Link> and <a
                        href={CLA_PRIVACY_POLICY_URL} target="_blank">Privacy Policy</a></span>
                    </label>
                    <ErrorMessage
                      name="agree"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                  <br/>

                  <div className="form-group">
                    <button type="submit" className="btn button btn-primary btn-block cla-btn"
                            disabled={submitting}>
                      {submitting && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Sign Up</span>
                    </button>
                  </div>
                </div>
              )}

              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
