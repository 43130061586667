import styles from "./RecordEntrySkeleton.module.scss";

export function RecordEntrySkeleton({ n = 1 }) {
  return (
    <div>
      {Array.from({length: n}).map((_, i) =>
        <div className="record-entry" key={`${i}`}>
          <div className={styles.skeleton} style={{ height: 100, width: 120 }}></div>
          <div className="record-entry-info">
            <div className={styles.skeleton} style={{ height: 22, width: '80%' }}></div>
            <div className={styles.skeleton} style={{ height: 18, width: '20%' }}></div>
            <div className={styles.skeleton} style={{ height: 14, width: '40%' }}></div>
          </div>
          <div></div>
        </div>
      )}
    </div>
  )
}