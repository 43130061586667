import IRecord from "../../types/record.type";
import { getUploadURL } from "../../api";

interface RecordTitleProps {
  record: IRecord;
}

export function RecordTitle(props: RecordTitleProps) {
  const r = props.record;
  const title = r.title ?? r.url;

  return (
    <div>
      <strong>
        {r.title ? <></> : <span className="error">NO TITLE: </span>}
        {r.finished ? (
          <a href={getUploadURL(r.id2)} target="_blank">{title}</a>
        ) : <span>{title}</span>}
      </strong>
    </div>
  )
}