import { API_URL, PRODUCT_NAME } from "../../config";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import "./ViewCopy.scss";
import useAPI from "../../api";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthContext";
import useTitle from "../../hooks/useTitle";

export function ViewCopy() {
  useTitle('View Copy');
  
  const api = useAPI();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [favoritedLoading, setFavoritedLoading] = useState(true);
  const [favorited, setFavorite] = useState(false);

  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    setFavoritedLoading(true);

    if (!id) {
      navigate("/");
      return;
    }
    api.record.isFavorited(id)
      .then((body) => setFavorite(!!body.favorited))
      .finally(() => setFavoritedLoading(false));
  }, []);

  const toggleFavorited = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!id) return;

    if (favorited) {
      api.record.removeFavorite(id).then(() => setFavorite(false));
    } else {
      api.record.addFavorite(id).then(() => setFavorite(true));
    }
  };

  if (!id) {
    return <Navigate to="/"/>;
  }

  return (
    <div className="view-copy-container">
      <div className="banner">
        <Link to="/">
          <img className="logo" src="/logo/logo.png" alt={`${PRODUCT_NAME} logo`}/>
        </Link>
        <span>
          This page was shared using <strong>{PRODUCT_NAME}</strong>

          &nbsp;&ndash;&nbsp;
          {!favoritedLoading &&
            (isAuthenticated ? <>
              <button className="link-button"
                      type="button"
                      onClick={toggleFavorited}>{favorited ? "remove" : "add"}
              </button>
              &nbsp;
              {favorited ? "from" : "to"} your favourites
            </> : <>
              <Link to="/login">sign in</Link> to your {PRODUCT_NAME} account
            </>)
          }


        </span>
      </div>


      <iframe className="copy-iframe" src={`${API_URL}uploads/raw/${id}`} frameBorder="0"></iframe>
    </div>
  );
}