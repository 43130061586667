import { Link } from "react-router-dom";
import "./About.scss";
import chromeStore from "../../assets/stores/chrome.png";
import firefoxStore from "../../assets/stores/firefox.svg";
import microsoftStore from "../../assets/stores/microsoft.svg";
import { STORE_URL_CHROME, STORE_URL_EDGE, STORE_URL_FIREFOX } from "../../config";
import logoCloser from "../../assets/brands/Closer.png";
import logoDrapers from "../../assets/brands/logo-drapers2.svg";
import logoPersonnelToday from "../../assets/brands/Personnel_Today.png";
import logoPRWeek from "../../assets/brands/PR_Week.png";
import logoTR from "../../assets/brands/TR-logo.svg";
import logoTTG from "../../assets/brands/TTG_Media.png";
import React from "react";
import useTitle from "../../hooks/useTitle";

export function About() {
  useTitle('About');

  return (
    <div className="container">
      <div className="contentContainer">
        <div className="contentDescription row">
          <div className="col">
            <h3>Getting started with ShareRight</h3>
            <p>Get full value from your digital news subscriptions by sharing pages and articles with your colleagues
              under the terms of your CLA Licence.</p>
            <p>Download the ShareRight browser extension today from your provider of choice and start sharing articles
              with your colleagues right away.</p>
          </div>
          <div className="col browserStores">
            <a href={STORE_URL_CHROME} target="_blank" title="Go to Chrome store to download the extension">
              <img src={chromeStore} alt="Chrome Web Store" className="chrome-store"/>
            </a>
            {/*<a href={STORE_URL_FIREFOX} target="_blank">*/}
            {/*  <img src={firefoxStore} alt="Firefox Add-ons" className="firefox-store"/>*/}
            {/*</a>*/}
            <a href={STORE_URL_EDGE} target="_blank" title="Go to Microsoft store to download the extension">
              <img src={microsoftStore} alt="Microsoft Edge store" className="edge-store"/>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>Repertoire sites include...</h4>
            <div className="contentBrands">
              <a href="https://closeronline.co.uk/"
                 target="_blank"
                 title="Go to 'Closer'"><img src={logoCloser} alt="closeronline.co.uk logo"/></a>
              <a href="https://www.drapersonline.com/"
                 target="_blank"
                 title="Go to 'Drapers'"><img src={logoDrapers} alt="drapersonline.com logo"/></a>
              <a href="https://www.personneltoday.com/"
                 target="_blank"
                 title="Go to 'Personnel Today'"><img src={logoPersonnelToday} alt="personneltoday.com logo"/></a>
              <a href="https://www.prweek.com/uk"
                 target="_blank"
                 title="Go to 'PR Week'"><img src={logoPRWeek} alt="prweek.com logo"/></a>
              <a href="https://www.talkingretail.com/"
                 target="_blank"
                 title="Go to 'Talking Retail'"><img src={logoTR} alt="talkingretail.com logo"/></a>
              <a href="https://www.ttgmedia.com/"
                 target="_blank"
                 title="Go to 'ttg'"><img src={logoTTG} alt="ttgmedia.com logo"/></a>
            </div>
          </div>
          <div className="contentDescription col">
            <h4>Extend the value of your subscriptions</h4>
            <p>Find an article that you want to share, then within a few clicks ShareRight can take a copy of the page
              you
              are viewing and provide you with all the tools you need to share with your colleagues.</p>
            <p>Not ready to read yet? ShareRight saves the article for you as it is today, so you can read at a time
              convenient to you - without the risk that the article will change, or the link will break.</p>
            <p>You can share and copy articles from any site that is included in the licence at no extra cost, as long
              as you have access as a user.</p>
          </div>
        </div>
      </div>
    </div>
  );
}