import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Footer, Navbar } from "./components";
import useAPI from "./api";
import { AuthContext } from "./providers/AuthContext";
import { CONTACT_CLA_EMAIL } from "./config";


const App: React.FC = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { logIn, logOut } = useContext(AuthContext);
  const api = useAPI();

  useEffect(() => {
    api.auth.info()
      .then((body) => {
        if (body && body.user) {
          logIn(body.user);
        } else {
          logOut();
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));

  }, []);

  if (loading || error) {
    return (
      <div style={{ position: "relative", height: "100vh" }}>
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          {loading ? (
            <div className="spinner-border"></div>
          ) : (
            <span>Failed to load. Please <a href={`mailto:${CONTACT_CLA_EMAIL}`}>contact support</a> if the problem recurs</span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar/>

      <div className="container mt-3">
        <Outlet/>
      </div>

      <Footer/>
    </div>
  );
};

export default App;