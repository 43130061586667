import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import useAPI from "../../api";
import * as Yup from "yup";
import useTitle from "../../hooks/useTitle";

export function ForgotPassword() {
  useTitle('Forgot password');

  const api = useAPI();

  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const initialValues: {
    email: string;
  } = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Field is required"),
  });

  const handleSave = (formValue: { email: string }) => {
    setSubmitting(true);

    api.auth.requestPasswordReset(formValue.email)
      .then((body) => {
        if (body.msg) {
          setMessage(body.msg);
        }
        if (body.type === "success") {
          setSuccess(true);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="content">
      <div className="card card-container cla-card">
        <h3>Forgot password</h3>
        <span>{message}</span>

        {success ? <></> : (
          <div className="card-inner">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSave}
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <Field name="email" type="text" className="form-control"/>
                  <ErrorMessage name="email" component="div" className="alert alert-danger"/>
                </div>

                <br/>

                <div className="form-group">
                  <button type="submit" className="btn button btn-primary btn-block cla-btn" disabled={submitting}>
                    {submitting && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Submit</span>
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
}