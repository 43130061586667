import { useRouteError } from "react-router-dom";
import { Navbar } from "../../components";
import useTitle from "../../hooks/useTitle";

interface ErrorResponse {
  status?: number;
  statusText?: string;
  message?: string;
}

export function Error() {
  useTitle('Error');

  const err = useRouteError() as ErrorResponse;
  console.error(err);

  return (
    <div className="error-page">
      <Navbar/>

      <div className="container mt-3">
        {err.status && err.status === 404 ? (
          <>
            <h1>Page not found!</h1>
            <p>
              The page you tried to visit was not found
            </p>
          </>
        ) : (
          <>
            <h1>Oops!</h1>
            <p>
              {err.statusText || err.message}
            </p>
          </>
        )}
      </div>
    </div>
  );
}