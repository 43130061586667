import { Field } from "formik";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import styles from "./PasswordField.module.scss";

interface PasswordFieldProps {
  id: string;
  name: string;
}

export function PasswordField({ id, name }: PasswordFieldProps) {
  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.password_field_container}>
      <Field id={id}
             name={name}
             type={visible ? 'text' : 'password'}
             className={`form-control ${styles.input}`}/>
      <FontAwesomeIcon icon={visible ? faEyeSlash : faEye}
                       title={visible ? 'Hide password' : 'Show password'}
                       className={styles.icon}
                       onClick={() => setVisible(!visible)}/>
    </div>
  );
}