import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { redirect } from "react-router-dom";
import useAPI from "../../api";
import useTitle from "../../hooks/useTitle";

export function ResetPassword() {
  useTitle('Reset password');

  const api = useAPI();

  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const query = new URLSearchParams(document.location.search);
  const email = query.get("email");
  const token = query.get("token");

  const initialValues: {
    password: string;
    repeatPassword: string;
  } = {
    password: "",
    repeatPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    repeatPassword: Yup.string().required("Required"),
  });

  const handleSave = (formValue: { password: string; repeatPassword: string }) => {
    setSubmitting(true);

    if (!email || !token) {
      redirect("/");
      return;
    }

    if (formValue.password !== formValue.repeatPassword) {
      setMessage("Passwords don't match");
      setSubmitting(false);
      return;
    }

    api.auth.resetPassword(email, token, formValue.password)
      .then((body) => {
        console.log(body);
        if (body.msg) {
          setMessage(body.msg);
        }
        if (body.type === "success") {
          setSuccess(true);
        }
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (!token || !email) {
      redirect("/");
    }
  }, []);

  return (
    <div className="container">
      <div className="card card-container cla-card">

        <h3>Reset password</h3>
        <span>{message}</span>

        {success ? <></> : (
          <div className="card-inner">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSave}
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="password">New Password</label>
                  <Field name="password" type="password" className="form-control"/>
                  <ErrorMessage name="password" component="div" className="alert alert-danger"/>
                </div>
                <div className="form-group">
                  <label htmlFor="repeatPassword">Repeat New Password</label>
                  <Field name="repeatPassword" type="password" className="form-control"/>
                  <ErrorMessage name="repeatPassword" component="div" className="alert alert-danger"/>
                </div>

                <br/>

                <div className="form-group">
                  <button type="submit" className="btn button btn-primary btn-block cla-btn" disabled={submitting}>
                    {submitting && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Save</span>
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
}