import "./TermsOfUse.scss";
import useTitle from "../../hooks/useTitle";

export function TermsOfUse() {
  useTitle('Terms of Use');

  return (
    <div className="container terms">
      <h3>Terms of Use</h3>

      <p>
        WHEREAS:
      </p>

      <p>
        (A) CLA and your company ("the Licensee") have entered into a licence for the purpose of reproducing from print
        and digital books, journals and magazines extracts from Licensed Material and the copying, storage,
        communication and use of Digital Copies and Digital Material (the "CLA Licence").
      </p>

      <p>
        (B) CLA has developed a web application tool called ShareRight that is intended to streamline access and
        facilitate sharing of Digital Copies under the CLA Licence, subject to other provisions set out below.
      </p>

      <p>
        (C) CLA has agreed to make available to the Licensee ShareRight (as defined below) for a pilot trial subject to
        and in accordance with these Terms of Use. The Licensee acknowledges that ShareRight is a new tool, its use is
        permitted as part of a pilot licence and the tool may be subject to change or its use terminated at CLA’s
        absolute discretion.
      </p>

      <h4>1. DEFINITIONS</h4>
      <p>
        1.1. All definitions used in the CLA Licence shall apply in these Terms of Use. The following additional
        definitions shall also apply. Where there is any conflict between these Terms of Use and the CLA Licence in
        respect of access to and use of ShareRight, these Terms of Use shall take priority. In all other respects,
        including in relation to rights in Licensed Material and any actual or alleged infringement of such rights, the
        terms of the CLA Licence shall take priority.
      </p>

      <p>
        <strong>"End User"</strong>: any individual user of the Licensee who is sharing Digital Copies on ShareRight as
        an Authorised Person to other Authorised Persons under the terms of the CLA Licence;
      </p>

      <p>
        <strong>"ShareRight"</strong>: the web application tool made available to the Licensee as part of their CLA
        Licence which can be installed via the browser plug-in provided by CLA and enables an End User securely to share
        a copy of a web page with other End Users;
      </p>

      <p>
        <strong>"Software"</strong>: the code, databases and other elements of ShareRight excluding Licensed Material;
      </p>

      <p>
        <strong>"Usage Data"</strong>: data collected by CLA on use of ShareRight including usage of specific Digital
        Material.
      </p>

      <p>
        1.2. All references to the singular may include the plural and vice versa as the context so requires and
        references to any gender shall include both genders. References to "include" and "including" shall be for
        illustrative purposes and not be construed to limit the sense of the preceding words.
      </p>

      <h4>2. Access to ShareRight</h4>
      <p>
        2.1 CLA shall make available ShareRight to the Licensee and End Users subject to and in accordance with these
        Terms of Use and the CLA Licence.
      </p>

      <p>
        2.2 CLA grants the Licensee a revocable, non-exclusive, non-transferable, limited licence to use the Software
        solely in accordance with these Terms of Use.
      </p>

      <p>
        2.3 In order to facilitate End User access to ShareRight, each End User will be provided login details, using
        their email address on the Licensee’s domain, which must not be disclosed to or used by anyone else at any time
        (whether on a temporary or permanent basis). Each End User shall ensure that they maintain their login details
        securely and shall notify CLA immediately if such details have or may have been disclosed to anyone else or
        subject to misuse.
      </p>

      <h4>3. CLA Aims, Rights and Obligations</h4>
      <p>
        3.1 CLA aims to ensure that ShareRight is available and operates correctly at all times and that content and
        data within ShareRight are securely backed up, however, it is agreed and acknowledged by CLA and the Licensee
        that:
      </p>

      <ul className="terms-list">
        <li>
          3.1.1 CLA makes no guarantee, representation or warranty that ShareRight is or will be up to date, free from
          errors or omissions, always available or uninterrupted, secure or free from bugs or viruses or that the
          content and data within ShareRight are or will be recoverable from backups;
        </li>
        <li>
          3.1.2 CLA reserves the right to limit or suspend ShareRight and/or any service to which it connects (in whole
          or in part) at any time with or without notice and without liability. Although CLA makes no commitment about
          any notice period, it aims where possible to notify the Licensee in advance of such non-availability;
        </li>
        <li>
          3.1.3 ShareRight is offered on an "as is" basis and CLA does not guarantee, represent or warrant that
          ShareRight will be fit for any particular purpose or that ShareRight and/or the use of any content or data
          accessible through ShareRight will meet the Licensee's requirements or that ShareRight or such content and
          data will be compatible and/or interoperable with the Licensee's own hardware and software systems, internet
          connection and network functionality; and
        </li>
        <li>
          3.1.4 CLA is not responsible for any delays, delivery failures, or any other loss or damage resulting from the
          transfer of data over communications networks and facilities, including the internet, and the Licensee
          acknowledges that ShareRight may be subject to limitations, delays and other problems inherent in the use of
          such communications facilities.
        </li>
      </ul>

      <p>
        3.2 CLA reserves the right to suspend access for some or all End Users to ShareRight immediately if CLA becomes
        aware of any activity which will or is likely to compromise the security or performance of ShareRight. In such
        event, an End User will be notified by CLA of the suspension occurring and the Licensee shall co-operate in good
        faith with CLA in order to seek to resolve said event as soon as possible.
      </p>

      <p>
        3.3 CLA reserves the right, at its sole discretion, to modify, add to or replace any element of ShareRight at
        any time with or without notice and without liability. Although CLA makes no commitment about any notice period,
        it aims to notify the Licensee in advance of such change in specifications but CLA does not guarantee, represent
        or warrant that ShareRight compatibility and/or interoperability with the Licensee's own hardware and software
        systems, internet connection and network functionality will not be adversely affected.
      </p>

      <h4>4. Licensee Obligations</h4>
      <p>
        4.1 The Licensee shall at all times:
      </p>

      <ul className="terms-list">
        <li>
          4.1.1 only use and allow End Users to use ShareRight for the sharing of Digital Copies with End Users in
          accordance with the CLA Licence and these Terms of Use and not for any other purpose;
        </li>
        <li>
          4.1.2 Ensure that End Users do not share Digital Copies with any person who is not an Authorised Person;
        </li>
        <li>
          4.1.3 seek to protect the security and integrity of ShareRight including by procuring that passwords and other
          login details for ShareRight are maintained securely in accordance with Clause 2.3 above;
        </li>
        <li>
          4.1.4 provide CLA with all reasonable co-operation as CLA may request and all reasonable access to such
          information as may be required by CLA including security access information and configuration services in
          order to enable CLA to make ShareRight available;
        </li>
        <li>
          4.1.5 comply with all applicable laws and regulations with respect to its activities in relation to
          ShareRight;
        </li>
        <li>
          4.1.6 carry out all Licensee responsibilities set out in these Terms of Use in a timely and efficient manner;
        </li>
        <li>
          4.1.7 obtain and maintain all necessary licences, consents, and permissions necessary for the Licensee, its
          contractors and agents to perform their obligations under these Terms of Use; and
        </li>
        <li>
          4.1.8 be solely responsible for procuring and maintaining its own hardware and software systems, internet
          connection and network functionality necessary to connect with and use ShareRight subject always to Clauses
          3.1.3 and 3.3 above.
        </li>
      </ul>

      <h4>5. Intellectual Property</h4>
      <p>
        5.1 Copyright in all Digital Copies stored in ShareRight remains with the Rightsholder.
      </p>
      <p>
        5.2 The Licensee acknowledges and agrees that CLA owns or licenses all intellectual property rights in
        ShareRight including all software, features, developments, adaptations, amendments, additions, derivative works
        and customisations designed, created, implemented or applied by CLA in respect of ShareRight whether or not at
        the request of the Licensee or jointly with the Licensee. Except as expressly stated herein, these Terms of Use
        do not grant the Licensee any rights to such intellectual property rights, or any other rights or licences in
        respect of ShareRight.
      </p>

      <p>
        5.3 The Licensee acknowledges that CLA owns all Usage Data and the intellectual property rights in such data,
        and subject to the provisions of Clause 6 below, CLA may use the Usage Data for the purposes of distribution of
        royalties to Rightsholders in accordance with the terms of the CLA Licence.
      </p>

      <h4>6. Licensee and End User Data</h4>
      <p>
        6.1 End Users’ personal details will be used by CLA to provide ShareRight and ensure its use is in accordance
        with the CLA Licence. End Users will ensure that they have consent from other Authorised Persons in their
        organisation to share their email address and any other personal details. CLA will use all personal data in
        accordance with its privacy policy found at <a href="https://cla.co.uk">cla.co.uk</a>.
      </p>
      <p>
        6.2 CLA and the Licensee shall each comply with applicable data protection law in relation to the provision and
        use of ShareRight.
      </p>

      <h4>7. Force Majeure</h4>
      <p>
        Neither party shall be responsible for any delay or failure to perform any obligation under these Terms of Use
        due to any cause that is outside the control of the party and could not be avoided by the exercise of due care,
        provided it shall notify the other party as soon as possible of such occurrence. Notwithstanding any such
        occurrence, each party shall at all times use reasonable efforts to perform its obligations in a timely manner,
        taking account of the existing circumstances.
      </p>

      <h4>8. Limitation of Liability</h4>
      <p>
        8.1 CLA and the Licensee acknowledge and agree that CLA is making ShareRight available to the Licensee without
        charge, that ShareRight may not meet the Licensee's particular requirements and that the Licensee is not
        required to use it and is free to consider alternative solutions.
      </p>
      <p>
        8.2 Except as expressly and specifically provided in these Terms of Use, all warranties, conditions and other
        terms implied by statute or common law are, to the fullest extent permitted by law, excluded from these Terms of
        Use.
      </p>
      <p>
        8.3 Nothing in these Terms of Use excludes the liability of any party:
      </p>

      <ul className="terms-list">
        <li>
          8.3.1 for death or personal injury caused by that party’s negligence; or
        </li>
        <li>
          8.3.2 for fraud or fraudulent misrepresentation;
        </li>
        <li>
          8.3.3 for any matter for which it would be unlawful to limit liability.
        </li>
      </ul>

      <p>
        8.4 Subject to Clause 8.3 above neither party shall in any circumstances be liable, whether in tort (including
        for negligence or breach of statutory duty howsoever arising), contract, misrepresentation (whether innocent or
        negligent) or otherwise for:
      </p>

      <ul className="terms-list">
        <li>
          8.4.1 loss of profits; or
        </li>
        <li>
          8.4.2 loss of business; or
        </li>
        <li>
          8.4.3 depletion of goodwill or similar losses; or
        </li>
        <li>
          8.4.4 loss of anticipated savings; or
        </li>
        <li>
          8.4.5 loss of goods; or
        </li>
        <li>
          8.4.6 loss of use; or
        </li>
        <li>
          8.4.7 loss or corruption of data or information; or
        </li>
        <li>
          8.4.8 any special, indirect, consequential, or pure economic loss, costs, damages, charges or expenses.
        </li>
      </ul>

      <h4>9. Termination</h4>
      <p>
        9.1 These Terms of Use shall commence on the date any End User confirms its binding acceptance of these Terms of
        Use and shall continue for the duration of the CLA Licence subject to the following provisions of this Clause 9.
      </p>

      <p>
        9.2 Upon the suspension, expiry or termination of the CLA Licence in accordance with its terms, these Terms of
        Use shall automatically be deemed suspended, expired or terminated accordingly and in no event shall these Terms
        of Use continue in effect after such suspension, expiry or termination of the CLA Licence. For the avoidance of
        doubt, suspension or termination of these Terms of Use under Clauses 9.3 or 9.4 below shall not affect the
        continuation of the CLA Licence in accordance with its terms.
      </p>

      <p>
        9.3 Should the Licensee or any End User commit any material breach of any of its obligations under these Terms
        of Use and remain in breach fourteen (14) days after receiving written notice from CLA to remedy the terms, the
        latter may by written notice to that effect delivered to the former forthwith:
      </p>

      <ul className="terms-list">
        <li>
          9.3.1 terminate these Terms of Use and access to ShareRight; or
        </li>
        <li>
          9.3.2 suspend the operation of these Terms of Use and access to or use of ShareRight until it shall be
          reasonably satisfied the breaches will not recur.
        </li>
      </ul>

      <p>
        9.4 CLA may decide to cease providing ShareRight to its licensees generally at any time without liability but
        CLA will give at least one month’s notice to End Users before discontinuing ShareRight or transferring its
        operation to a third party.
      </p>
      <p>
        9.5 Termination or suspension of these Terms of Use pursuant to this Clause 9 shall be without prejudice to any
        rights or remedies of the parties.
      </p>
      <p>
        9.6 Upon suspension, expiry or termination of these Terms of Use for whatever reason, access to and use of
        ShareRight by the Licensee and its End Users shall immediately cease.
      </p>

      <h4>10. Severability</h4>
      <p>
        If any provision of these Terms of Use is held to be unenforceable or invalid, such provision will be changed
        and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
        law and the remaining provisions will continue in full force and effect.
      </p>

      <h4>11. Variation of Terms</h4>
      <p>
        These Terms of Use embody all the terms agreed between the parties and no oral representations, warranties or
        promises shall be implied as terms hereof. These Terms of Use may be varied by CLA by written notice to the
        Licensee.
      </p>

      <h4>12. Disputes and Governing Law</h4>
      <p>
        These Terms of Uses shall be governed by and construed in accordance with English Law and subject to the
        exclusive jurisdiction of the English Courts. If any dispute arises out of these Terms of Use the parties will
        attempt to settle it by mediation in accordance with Centre for Dispute Resolutions ("CEDR") Model Mediation
        Procedures. To initiate a mediation a party must give notice in writing ("ADR Notice") to the other party to the
        dispute requesting mediation. If there is any point on the conduct of the mediation upon which the parties
        cannot agree within fourteen (14) days from the date of the ADR Notice, CEDR will, at the request of any party,
        decide that point for the parties having consulted with them. The mediation will start not later than twenty
        eight (28) days after the date of the ADR Notice. The commencement of a mediation will not prevent the parties
        commencing or continuing Court or where appropriate Copyright Tribunal proceedings nor will it prevent CLA
        exercising its rights under these Terms of Use generally.
      </p>
    </div>
  );
}