export const PRODUCT_NAME = "ShareRight";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const ADMIN_URL = process.env.REACT_APP_API_URL ? new URL(process.env.REACT_APP_API_URL).origin : 'https://api.clashareright.co.uk';

export const CONTACT_CLA_EMAIL = "support@clashareright.zendesk.com";
export const CLA_KNOWLEDGE_BASE_URL = "https://clashareright.zendesk.com/";
export const CLA_PRIVACY_POLICY_URL = "https://cla.co.uk/privacy-policy/";
export const CLA_COPYRIGHT_NOTICE_URL = "https://www.cla.co.uk/copyright-notice/";
export const CLA_ACCESSIBILITY_URL = "https://www.cla.co.uk/accessibility-policy/";
export const CLA_ANTI_SLAVERY_URL = "https://www.cla.co.uk/anti-slavery-policy/";

export const STORE_URL_CHROME = "https://chrome.google.com/webstore/detail/shareboxv3/lgipmkdpfcljcmapbdaibldkjgomopef";
export const STORE_URL_FIREFOX = "https://addons.mozilla.org/en-US/firefox/addon/shareright/";
export const STORE_URL_EDGE = "https://microsoftedge.microsoft.com/addons/detail/shareboxv3/nacoegpkjlejbmabkcoehcjiobekkdnb";
