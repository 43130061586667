import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { FormEvent, useContext, useEffect, useState } from "react";
import useAPI, { getUploadURL } from "../../api";
import IRecord from "../../types/record.type";
import "./Share.scss";
import {
  EmailAutocompleteInput,
  EmailCard,
  EmailCheckModal,
  RecordDeleteButton,
  RecordFavoriteButton,
  RecordThumbnail,
} from "../../components";
import { CONTACT_CLA_EMAIL, PRODUCT_NAME } from "../../config";
import { uniqueArray } from "../../common/arrays";
import { faCopy, IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../../providers/AuthContext";
import { getDomain } from "../../common/email";
import useTitle from "../../hooks/useTitle";

interface Message {
  type: "success" | "error" | "warning";
  msg: string;
}

export function Share() {
  useTitle('Share');

  const params = useParams();
  const id = params.id;

  const [shared, setShared] = useState(false);

  const [record, setRecord] = useState<IRecord | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [message, setMessage] = useState<Message | null>(null);

  const [emailCheck, setEmailCheck] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [parsedEmails, setParsedEmails] = useState<string[]>([]);
  const [emailNames, setEmailNames] = useState<Record<string, string>>({});
  const [messageInput, setMessageInput] = useState("");

  const [outsiderEmails, setOutsiderEmails] = useState<string[]>([]);

  const [copyIcon, setCopyIcon] = useState<IconDefinition>(faCopy);

  const { user } = useContext(AuthContext);

  const api = useAPI();
  const navigate = useNavigate();

  // Retry fetching record info every 5 seconds as long as status is Processing
  const poll = () => {
    if (!id) {
      return;
    }
    setTimeout(() => {
      api.record.get(id)
        .then((body) => {
          if (body.record) {
            setRecord(body.record);
            if (body.record.status === 0) {
              poll();
            }
          } else {
            navigate("/");
          }
        })
    }, 5000);
  };

  useEffect(() => {
    loadRecord();
  }, []);

  useEffect(() => {
    if (!parsedEmails.length || !user || !id) {
      return;
    }

    setOutsiderEmails([]);

    const s1 = getDomain(user.email);
    const outsiders: string[] = [];
    for (let i = 0; i < parsedEmails.length; i++) {
      if (s1 !== getDomain(parsedEmails[i])) {
        outsiders.push(parsedEmails[i]);
      }
    }
    if (outsiders.length > 0) {
      setOutsiderEmails(outsiders);
    }

    api.user.info(parsedEmails)
      .then((body) => {
        if (body.users) {
          const s = {} as Record<string, string>;
          body.users.forEach((u: any) => {
            s[u.email] = u.name;
          });
          setEmailNames(s);
        }
      });
  }, [parsedEmails]);

  if (!id) {
    return <Navigate to="/"/>;
  }

  const isValidEmail = (email: string) => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return reg.test(email);
  }

  const parseEmails = (input?: string) => {
    const inp = input || emailInput;
    const emails = inp.split(/[\s,;]/).filter((s) => s.trim() !== "");
    const filtered = emails.filter((e) => isValidEmail(e));
    setParsedEmails(oldParsedEmails => uniqueArray([...oldParsedEmails, ...filtered]));
    setEmailInput("");
  };

  const removeEmail = (email: string) => {
    const newParsed = [...parsedEmails]; // copy
    const i = newParsed.findIndex((s) => s === email);
    if (i !== -1) {
      newParsed.splice(i, 1);
      setParsedEmails(() => newParsed);
    }
  };

  const submitShare = (confirmedEmails?: string[], linkOnlyEmails?: string[]) => {
    if (shared) {
      setShared(false);
      setMessage(null);
      return;
    }

    setEmailCheck(false);
    setMessage(null);

    // emails receives link to the copy, outsiders receives a redirect link to the original webpage
    let emails = [...parsedEmails]; // copy
    if (confirmedEmails !== undefined) {
      emails = [...new Set([...confirmedEmails, ...emails])]; // remove duplicates
    }

    let linkOnly: string[] = [];
    if (linkOnlyEmails !== undefined) {
      linkOnly = linkOnlyEmails;

      // Remove all emails which are in linkOnly
      emails = emails.filter((e) => linkOnly.indexOf(e) === -1);
    }

    if (emails.length + linkOnly.length === 0) {
      setMessage({
        type: 'error',
        msg: "You must enter at least one email address to share with",
      });
      return;
    }

    // If we have any emails with different domains, show the email confirmation dialog, unless the record is outside
    // the whitelist as then there's no need as only redirect links will be sent
    if (record?.status !== 3 && confirmedEmails === undefined && outsiderEmails.length > 0) {
      setEmailCheck(true);
      return;
    }

    setSubmitting(true);

    // If page is outside whitelist, we can only send link. Set all emails as outsiders
    if (record?.status === 3) {
      linkOnly = [...emails, ...linkOnly];
      emails = [];
    }

    api.record.share(id, emails, messageInput, linkOnly)
      .then((body) => {
        setMessage({
          type: body.type,
          msg: body.msg,
        });
        if (body.type === 'success') {
          setShared(true);
        }
      }).finally(() => setSubmitting(false));
  };


  const loadRecord = () => {
    setLoading(true);

    Promise.all([
      api.record.get(id)
        .then((body) => {
          if (body && body.record) {
            setRecord(body.record);
            if (body.record.status === 0) {
              poll();
            }
          } else {
            navigate("/");
          }
        }),
      api.record.shares(id)
        .then((body) => {
          if (body && body.shares) {
            setMessageInput(body.latest_message || '');
            setShared(true);
            setParsedEmails(body.shares);
          }
        }),
    ]).then(() => {
      setLoading(false);
    });
  };

  const copyToClipboard = async (s: string) => {
    await navigator.clipboard.writeText(s);
  };


  const getShareLink = () => {
    if (loading || !record) {
      return;
    }

    const swapIcon = () => {
      setCopyIcon(faCheck);
      setTimeout(() => {
        setCopyIcon(faCopy);
      }, 800);
    }

    if (record.finished) {
      return <span>
        <a href={getUploadURL(record.id2)} target="_blank">{getUploadURL(record.id2)}</a>
        &nbsp;
        <button type="button"
                className="icon-btn copy-btn"
                onClick={() => {
                  copyToClipboard(getUploadURL(record.id2));
                  swapIcon();
                }}>
          <FontAwesomeIcon icon={copyIcon} title="Copy link to clipboard"/>
        </button>
        &nbsp;
        <a href={getUploadURL(record.id2)} target="_blank">Preview</a>
      </span>;
    } else if (record.status === 3) {
      return <span>
        <a href={record.url} target="_blank">{record.url}</a>
        &nbsp;
        <button type="button"
                className="icon-btn copy-btn"
                onClick={() => {
                  copyToClipboard(record.url);
                  swapIcon();
                }}>
          <FontAwesomeIcon icon={copyIcon} title="Copy link to clipboard"/>
        </button>
      </span>;
    } else if (record.status === 2) { // failed
      return <span>Failed to copy page, please try again or <a
        href={`mailto:${CONTACT_CLA_EMAIL}`}>contact us</a>.</span>;
    } else {
      return <>
        <div className="spinner-border spinner-border-sm"></div>
        &nbsp;
        Loading...
      </>;
    }
  };

  return (
    <div className="container">
      {!loading && record && (
        <>
          <h4>
            Share your page
            <RecordFavoriteButton id2={record.id2} favorited={record.favorited}
                                  style={{ display: "inline-block", marginLeft: "5px" }}/>
            <RecordDeleteButton id2={record.id2}
                                style={{ display: "inline-block", marginLeft: "5px" }}
                                afterDelete={() => navigate("/")}/>
          </h4>
          <div className="share-view">
            <ul className="share-info">
              <li>
                <RecordThumbnail record={record} wide/>
              </li>
              <li>
                <strong className={`${!record.title && 'error'}`}>{record.title ? record.title : "NO TITLE"}</strong>
              </li>
              <li>
                {new URL(record.url).hostname.replace("www.", "")}
              </li>
              <li>
                <a href={record.url} target="_blank" rel="noreferrer">{record.url}</a>
              </li>
            </ul>
            <div>

              {record.status === 2 ?
                <div className="response-box response-error" style={{ marginTop: 0 }}>
                  Failed to copy page, please try again or <a href={`mailto:${CONTACT_CLA_EMAIL}`}>contact us</a>.
                </div>
                :
                <>
                  <div className="share-section">
                    <header>Your {PRODUCT_NAME} Link:</header>
                    {getShareLink()}
                    <br/>
                  </div>

                  {message && <div className={`response-box response-${message.type}`}>{message.msg}</div>}

                  {!shared && <div className="share-section">
                    <header>Share with people in your organisation:</header>
                    <small>Enter the email address(es) of all the people to share with (separated by commas):</small>
                    <form action="" className="email-input" onSubmit={(e: FormEvent) => {
                      e.preventDefault();
                      parseEmails();
                    }}>
                      <EmailAutocompleteInput
                        onAdd={(val) => parseEmails(val)}
                        setEmailInput={(value) => setEmailInput(value)}
                        hide={parsedEmails}
                      />
                    </form>
                  </div>}

                  {parsedEmails.length > 0 ? <div className="share-section">
                    <header>Share{shared && 'd'} with:</header>

                    <div className="email-cards">
                      {parsedEmails.map((email) => (
                        <EmailCard key={email}
                                   email={email}
                                   name={emailNames[email] ?? null}
                                   canDelete={!shared}
                                   onRemove={removeEmail}/>
                      ))}
                    </div>
                  </div> : <br/>}

                  <div className="share-section">
                    <header>{shared ? 'Your' : 'Personalize your'} message:</header>
                    {shared ?
                      <span>{messageInput}</span> :
                      <textarea value={messageInput}
                                onChange={(e) => setMessageInput(e.target.value)}
                                style={{ width: "100%" }}
                                placeholder="Enter a message (optional)" cols={40} rows={4}></textarea>}
                  </div>
                  <div className="share-section share-actions">
                    <Link to="/">{shared ? 'Back to My ShareRight' : 'Cancel'}</Link>
                    <form action="" onSubmit={(e: FormEvent) => {
                      e.preventDefault();
                      submitShare();
                    }}>
                      <button type="submit"
                              className="sharebox-btn"
                              disabled={(!record.finished && record.status !== 3) || submitting}>
                        {submitting ? <>
                          <div className="spinner-border spinner-border-sm"></div>
                          &nbsp;
                          Sharing...
                        </> : <>Share {shared ? 'again' : 'page'}</>}
                      </button>

                      {emailCheck &&
                        <EmailCheckModal emails={outsiderEmails}
                                         onCancel={() => setEmailCheck(false)}
                                         onConfirm={(confirmedEmails, linkOnlyEmails) => submitShare(confirmedEmails, linkOnlyEmails)}/>}

                    </form>
                  </div>
                </>
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
}