import { PRODUCT_NAME } from "../../config";
import { SetStateAction, useContext, useEffect, useState } from "react";
import useAPI from "../../api";
import "./MyCopies.scss";
import {
  Checkbox,
  RecordDeleteButton,
  RecordEntrySkeleton,
  RecordFavoriteButton,
  RecordThumbnail,
  RecordTitle
} from "../../components";

import { format } from "date-fns";
import { Link, Navigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContext";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;
import useTitle from "../../hooks/useTitle";

export function MyCopies() {
  useTitle('My Copies');

  const api = useAPI();

  const { isAuthenticated } = useContext(AuthContext);

  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  // Search filters
  const [search, setSearch] = useState("");
  const [sharedByMeCheck, setSharedByMeCheck] = useState(false);
  const [sharedWithMeCheck, setSharedWithMeCheck] = useState(false);
  const [favoritesCheck, setFavoritesCheck] = useState(false);

  // Pagination. TODO: move to component for reusability
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [visiblePages, setVisiblePages] = useState<Array<string | number>>([]);

  useEffect(() => {
    // Calculate which pages to show
    const padding = 3;
    let startPage: number, endPage: number;

    if (totalPages <= (2 * padding) + 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (page <= padding + 3) {
      startPage = 1;
      endPage = (2 * padding) + 3;
    } else if (page >= totalPages - (padding + 2)) {
      startPage = totalPages - (2 * padding) - 2;
      endPage = totalPages;
    } else {
      startPage = page - padding;
      endPage = page + padding;
    }

    const final = [];
    if (startPage > 1) {
      final.push(1);
    }
    if (startPage > 2) {
      final.push("...");
    }
    for (let i = startPage; i <= endPage; i++) {
      final.push(i);
    }
    if (endPage < totalPages - 1) {
      final.push("...");
    }
    if (endPage < totalPages) {
      final.push(totalPages);
    }

    setVisiblePages(final);
  }, [totalPages, page]);

  const handleCheckToggle = (val: boolean, setVal: SetStateAction<any>) => {
    setVal(!val);
  };

  useEffect(() => {
    loadRecords();
  }, [page, sharedByMeCheck, sharedWithMeCheck, favoritesCheck]);

  if (!isAuthenticated) {
    return <Navigate to="/home"/>;
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const loadRecords = () => {
    setLoading(true);
    api.record.list({
      page,
      limit: 10,
      search,
      filters: {
        sharedByMe: sharedByMeCheck,
        sharedWithMe: sharedWithMeCheck,
        favorites: favoritesCheck,
      },
    })
      .then((body) => {
        setRecords(body.records);
        if (body.pagination) {
          setTotalPages(body.pagination.total_pages);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <h3>My {PRODUCT_NAME}</h3>
        <div className="record-view">
          <div>
            <h4>Filters</h4>
            <div>
              <Checkbox label="Shared by me"
                        checked={sharedByMeCheck}
                        onChange={() => handleCheckToggle(sharedByMeCheck, setSharedByMeCheck)}
                        disabled={loading}/>
              <Checkbox label="Shared with me"
                        checked={sharedWithMeCheck}
                        onChange={() => handleCheckToggle(sharedWithMeCheck, setSharedWithMeCheck)}
                        disabled={loading}/>
              <Checkbox label="Favourites"
                        checked={favoritesCheck}
                        onChange={() => handleCheckToggle(favoritesCheck, setFavoritesCheck)}
                        disabled={loading}/>
            </div>
          </div>
          <div>
            <div className="search-container">
              <form action="" onSubmit={(e) => {
                e.preventDefault();
                loadRecords();
              }}>
                <input className="search-input"
                       type="text"
                       placeholder={`Search your ${PRODUCT_NAME}`}
                       value={search}
                       onChange={(e) => setSearch(e.target.value)}
                       disabled={loading}/>
              </form>
            </div>
            <div>
              {loading ? <RecordEntrySkeleton n={3}/>
                 : (records ? (
                  records.map((r: any) => (
                    <div key={r.id2} className="record-entry">
                      <RecordThumbnail record={r}/>
                      <div className="record-entry-info">
                        <RecordTitle record={r}/>
                        <div>
                          {new URL(r.url).hostname.replace("www.", "")}
                        </div>
                        <div className="details">
                          Saved: {format(new Date(r.created * 1000), "dd/MM/yyyy HH:mm")}
                          &nbsp;
                          {r.me && <>Viewed {r.views} times</>}
                          {r.me && r.shares > 0 ? (
                            <>
                              &nbsp;
                              Shared with {r.shares} {r.shares === 1 ? 'person' : 'people'}
                            </>
                          ) : <></>}
                        </div>
                      </div>
                      <div>
                        {r.me && <Link to={`/share/${r.id2}`} className="sharebox-btn">
                          Share
                        </Link>}

                        <div className="action-buttons">
                          <RecordFavoriteButton id2={r.id2} favorited={r.favorited}/>
                          {r.me && <RecordDeleteButton id2={r.id2} afterDelete={loadRecords}/>}
                        </div>

                      </div>
                    </div>
                  ))
                ) : <div>No records yet</div>)
              }
            </div>
            <div>
              <div className="paginator">
                {totalPages > 0 ? (
                  <ul>
                    <li>
                      <button type="button" onClick={prevPage}>&larr;</button>
                    </li>

                    {visiblePages.map((e, i) => (
                      <li key={`${e}-${i}`}>
                        {e === '...' && <div className="fill">...</div>}
                        {e !== '...' &&
                          <button className={`${typeof e === 'number' && page === e && 'active'}`}
                                  type="button"
                                  onClick={() => setPage(e as number)}
                          >{e}</button>}
                      </li>
                    ))}

                    <li>
                      <button type="button" onClick={nextPage}>&rarr;</button>
                    </li>
                  </ul>
                ) : <></>}
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}