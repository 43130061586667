/**
 * Responsible for sending API requests. Not responsible for parsing or interpreting results of any requests.
 */
import { API_URL, BASE_URL } from "../config";

export const getUploadURL = (id2: string) => {
  return `${BASE_URL}s/${id2}`;
};

export default function useAPI() {
  const apiFetch = async (resource: RequestInfo, init?: RequestInit): Promise<any> => {
    if (!init) {
      init = { credentials: 'include' };
    } else {
      init['credentials'] = 'include';
    }

    const response = await fetch(resource, init);

    // Response may be empty, check before attempting to parse as JSON
    const plainBody = await response.text();
    let body = null;
    if (plainBody) {
      body = JSON.parse(plainBody);
    }
    return body;
  };

  return {
    auth: {
      login(email: string, password: string) {
        return apiFetch(`${API_URL}auth/login`, {
          body: new URLSearchParams({ email, password }),
          method: 'POST',
        });
      },
      register(email: string, password: string, company?: string) {
        const body = new URLSearchParams({ email, password });
        if (company) body.set('company', company);

        return apiFetch(`${API_URL}auth/register`, {
          body,
          method: 'POST',
        });
      },
      logOut() {
        return apiFetch(`${API_URL}auth/logout`, {
          method: 'POST',
        });
      },
      info() {
        return apiFetch(`${API_URL}auth/info`);
      },
      requestPasswordReset(email: string) {
        return apiFetch(`${API_URL}auth/request-reset`, {
          method: 'POST',
          body: new URLSearchParams({ email }),
        });
      },
      resetPassword(email: string, token: string, password: string) {
        return apiFetch(`${API_URL}auth/reset`, {
          method: 'POST',
          body: new URLSearchParams({ email, token, password }),
        });
      },
    },
    contacts: {
      get() {
        return apiFetch(`${API_URL}contacts`);
      }
    },
    user: {
      info(emails: string[]) {
        const a = emails.map((e) => encodeURI(e));
        const qs = new URLSearchParams({ emails: a.join(",") });
        return apiFetch(`${API_URL}user/info?` + qs);
      },
      saveDetails(name: string) {
        return apiFetch(`${API_URL}user/account`, {
          method: 'POST',
          body: new URLSearchParams({name}),
        });
      }
    },
    record: {
      list(options: { page?: number; limit?: number; search?: string; filters: Record<any, any> }) {
        const params = new URLSearchParams();
        if (options.page) params.set("page", options.page.toString());
        if (options.limit) params.set("limit", options.limit.toString());
        if (options.search) params.set("q", options.search);
        if (options.filters) {
          if (options.filters.sharedByMe) params.set("sharedByMe", "1");
          if (options.filters.sharedWithMe) params.set("sharedWithMe", "1");
          if (options.filters.favorites) params.set("favorites", "1");
        }

        return apiFetch(`${API_URL}record${params ? ('?' + params) : ''}`);
      },
      share(id: number | string, emails: string[], message?: string, outsiders?: string[]) {
        const body = new URLSearchParams({ emails: emails.join(",") });
        if (message) body.set("message", message);
        if (outsiders) body.set("emails_link", outsiders.join(","));

        return apiFetch(`${API_URL}record/${id}/share`, {
          method: 'POST',
          body,
        });
      },
      shares(id: number | string) {
        return apiFetch(`${API_URL}record/${id}/shares`);
      },
      delete(id: number | string) {
        return apiFetch(`${API_URL}record/${id}`, {
          method: 'DELETE',
        });
      },
      get(id: string) {
        return apiFetch(`${API_URL}record/${id}`);
      },
      isFavorited(id2: string) {
        return apiFetch(`${API_URL}record/${id2}/favorite`);
      },
      addFavorite(id: string) {
        return apiFetch(`${API_URL}record/${id}/favorite`, {
          method: 'POST',
        });
      },
      removeFavorite(id: string) {
        return apiFetch(`${API_URL}record/${id}/favorite`, {
          method: 'DELETE',
        });
      },
    },
  };
}