import React, { useContext, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import useAPI from "../../api";
import { AuthContext } from "../../providers/AuthContext";
import { PRODUCT_NAME } from "../../config";
import { PasswordField } from "../../components";
import useTitle from "../../hooks/useTitle";

type Props = {}

export const Login: React.FC<Props> = () => {
  useTitle('Sign in');

  const navigate = useNavigate();

  const api = useAPI();
  const { logIn } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const initialValues: {
    email: string;
    password: string;
  } = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Field is required"),
    password: Yup.string().required("Field is required"),
  });

  const handleLogin = (formValue: { email: string; password: string }) => {
    const { email, password } = formValue;

    setMessage("");
    setLoading(true);

    api.auth.login(email, password).then((body) => {
      if (body && body.type === "success") {
        console.log("Successfully logged in");
        logIn({email});

        navigate("/about");
        window.location.reload();
      } else if (body.type === "error" && body.msg) {
        setMessage(body.msg);
      }
    }).catch(() => setMessage("Failed to log in, please try again"))
      .finally(() => setLoading(false));
  };

  return (
    <div className="col-md-12">
      <div className="card card-container cla-card">
        <div>
          <h1>Sign in to {PRODUCT_NAME}</h1>
          <div className="card-inner">
            <span>The save-and-share service from the Copyright Licensing Agency</span>
            <br/>
            <span>
            Dont have an account? &nbsp;<Link to="/register">Sign up now</Link>
          </span>
          </div>
        </div>
        <div className="card-inner">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field name="email" type="text" className="form-control"/>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="alert alert-danger"
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                {/*<Field name="password" type="password" className="form-control"/>*/}
                <PasswordField id="password" name="password"/>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="alert alert-danger"
                />
              </div>

              <div className="form-group">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>

              <br/>

              <div className="form-group">
                <button type="submit" className="btn button btn-primary btn-block cla-btn" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
